<template>
  <div class="company px-2">
    <div class="search pt-3 mb-4">
      <div class="row">
        <div class="flex md8 xs12">
          <va-input label="企业名称" placeholder="请输入关键字" v-model="keyword" clearable/>
        </div>
        <div class="flex md4 xs12">
          <va-button :rounded="false" class="px-2" @click="searchRecords">搜索</va-button>
          <a href="javascript:void(0)" @click="isAdvancedSearch = !isAdvancedSearch" class="normal px-2"><strong>高级查找</strong></a>
        </div>
      </div>
      <div class="row" v-if="isAdvancedSearch">
        <div class="flex md4 xs12">
          <va-select label="项目类别"  outline v-model="advancedSearchOption.projectType" :options="selectOptions.projectTypes" :text-by="option => option.key" :value-by="option => option.value" clearable/>
        </div>
        <div class="flex md4 xs12">
          <va-select label="沟通方式" outline v-model="advancedSearchOption.communicationType" :options="selectOptions.communicationType" :text-by="option => option.key" :value-by="option => option.value"  clearable />
        </div>
        <div class="flex md4 xs12">
          <va-date-input label="沟通时间" clearable v-model="advancedSearchOption.communicateOn" :format="formatDate" outline />
        </div>
        <div class="flex md4 xs12">
          <va-input class="" label="项目参与人" v-model="advancedSearchOption.ownerFullName" outline clearable />
        </div>
        <div class="flex md4 xs12">
          <va-input class="" label="牵头部门" v-model="advancedSearchOption.headDept" outline clearable />
        </div>
        <div class="flex md4 xs12">
          <va-input class="" label="配合部门" v-model="advancedSearchOption.assitDept" outline clearable />
        </div>
      </div>
    </div>
    <div class="ribbon-btn py-1">
      <va-button icon="add" size="small" flat :rounded="false" @click="gotoNewPage">新增</va-button>
      <va-button icon="delete" size="small" :rounded="false" flat :disabled="selectedCommunications.length < 1" @click="deleteRecords">删除</va-button>
      <va-button icon="edit" size="small" flat :rounded="false" @click="gotoEdit" :disabled="selectedCommunications.length != 1">明细</va-button>
      <va-button icon="share" size="small" :rounded="false" flat :disabled="selectedCommunications.length <= 0" @click="openShareRecoreModal" v-if="hasPermissionToShare">共享</va-button>
      <va-button icon="file_download" size="small" :rounded="false" flat :disabled="selectedCommunications.length === 0" @click="ExportXlsx" v-if="!isMobile">导出</va-button>
    </div>
    <div class="table">
      <ag-grid-vue
        style="height: 100%;"
        class="ag-theme-material"
        :columnDefs="columnColumnDefs"
        :headerHeight="45"
        rowSelection="multiple"
        @selection-changed="onSelectionChanged"
        @grid-ready="onGridReady" />
    </div>
    <div class="pagination">
      <v-pagination
        v-model="currentPage"
        :pages="pageCount"
        :range-size="1"
        @update:modelValue="currengPageChanged"
        active-color="#DCEDFF" />
    </div>
  </div>
  <va-modal v-model="showShareModal" title="分享基本信息" hide-default-actions
    ref="shareRecordModal" 
    :no-outside-dismiss = "true"
    :mobile-fullscreen="false">
    <template #default>
      <div style="width:300px;">
        <va-select label="用户" outline clearable searchable multiple
          v-model="selectedUserForShare"
          :options="shareTargetUsers" 
          :text-by="option => option.key"
          @update-search="searchUser"
          no-options-text="暂无结果" placeholder="请选择分享给的用户">
          <template #appendInner>
            <va-icon name="person"/>
          </template>
        </va-select>
        <va-list class="py-1" v-if="sharedRecords.length > 0">
          <va-list-item v-for="r in sharedRecords" :key="r.id">
            <va-list-item-section>
              {{r.assignToUser.fullName}}
            </va-list-item-section>
            <va-list-item-section icon>
              <va-button icon="visibility_off" size="small" :rounded="false" @click="cancelRecordShare(r)" title="取消分享" flat/>
            </va-list-item-section>
          </va-list-item>
        </va-list>
      </div>
    </template>
    <template #footer>
      <va-button :rounded="false" class="px-2" :disabled="!selectedUserForShare || selectedUserForShare.length === 0" @click="shareRecord">确认</va-button>
      <va-button :rounded="false" class="ml-3 px-2" outline flat @click="$refs.shareRecordModal.hide()">取消</va-button>
    </template>
  </va-modal>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, onBeforeMount, computed, getCurrentInstance } from 'vue'
import moment from 'moment';
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { AgGridVue, } from "ag-grid-vue3";
import { GridReadyEvent, GridApi, ColumnApi } from "ag-grid-community";
import VPagination from "@hennge/vue3-pagination";
import "@/sass/pagination.scss";
import axiosService from '@/services/AxiosService'
import { SelectOption } from '@/services/SelectOptionService'
import useSelectOptions from '@/common/CommonOptions'
import CommonUtil from '@/services/CommonUtil'
import selectOptionService from '@/services/SelectOptionService'
import { ExportExcelService } from '@/services/ExportExcelService'
import { Communication, CommunicationInGrid, CommunicationParticipants, User, RecordShare } from '@/model'
import { AxiosResponse } from 'axios';
import { useToast } from 'vuestic-ui'

export default defineComponent({
  components: {
    AgGridVue,
    VPagination
  },
  setup() {
    const { init : initToast } = useToast()
    const router = useRouter()
    const route = useRoute()
    const app = getCurrentInstance()
    const columnColumnDefs = [
        { headerName: "序号", field: "serialNo", width:120,checkboxSelection: true, headerCheckboxSelection: true },
        { headerName: "企业名称", field: "company.companyName", width:250, resizable:true },
        { headerName: "项目类别", field: "company.projectType", resizable:true, cellRenderer: (params: {value: string}) => selectOptionService.optionCollection.get('ProjectType')?.find(o => o.value == params.value)?.key },
        { headerName: "沟通方式", field: "communicationType", resizable:true, cellRenderer: (params: {value: string}) => selectOptionService.optionCollection.get('CommunicationType')?.find(o => o.value == params.value)?.key },
        { headerName: "沟通时间", field: "communicateOn", cellRenderer: (params: {value: string}) => moment(params.value).format('ll'), resizable:true },
        { headerName: "牵头部门", field: "headDepartment", resizable: true },
        { headerName: "配合部门", field: "cooperationDepartment", resizable: true },
        { headerName: "具体负责人", field: "owners", resizable: true },
        { headerName: "配合人员", field: "ourParticipateMembers", resizable: true },
        { headerName: "进展情况", field: "feedbackAfterContact", resizable: true, cellRenderer: (params : {value: string}) => `<span title='${params.value}'>${params.value}</span>`  },
        { headerName: "存在问题", field: "potentialQuestions", resizable: true, minWidth: 350, flex: 1 }
    ]
    const communications = reactive([] as Array<CommunicationInGrid>)
    let selectedCommunications = reactive([] as Array<CommunicationInGrid>);
    const keyword = ref('')
    let gridApi: GridApi
    let gridColumnApi: ColumnApi 
    let recordCount = ref(1)
    const currentPage = ref(1)
    const pageSize = ref(30)
    const pageCount = computed(() => Math.ceil(recordCount.value / pageSize.value))
    const store = useStore()
    function currengPageChanged(page: number)
    {
      currentPage.value = page
       searchRecords()
    }

    function gotoNewPage() {
      router.push('/communication/add-communication')
    }

    function gotoEdit() {
      router.push(`/communication/${selectedCommunications[0].id}`)
    }

    function deleteRecords() {
      if(window.confirm("请确认是否删除？")) {
        selectedCommunications.forEach(c => {
          axiosService.delete(`api/communications/${c.id}`).then((res) => {
            initToast({
              message: '删除成功！',
              color: 'success',
              closeable: true,
              duration: 2000
            })
            searchRecords()
          })
        })
      }
    }

    function onSelectionChanged() {
      selectedCommunications.splice(0, selectedCommunications.length)
      Array.prototype.push.apply(selectedCommunications, gridApi.getSelectedRows() as Array<CommunicationInGrid>)
    }

    function onGridReady(params: GridReadyEvent) {
      gridApi = params.api
      gridColumnApi = params.columnApi
      searchRecords()
    }

    const isAdvancedSearch = ref(false)
    const advancedSearchOption = reactive({
      projectType: '',
      communicateOn: '',
      communicationType: '',
      companyType: '',
      ownerFullName: '',
      headDept: '',
      assitDept: ''
    })

    function formatDate(date: string) {
      if(date){
        return moment(date).format('yyyy/MM/DD')
      } else {
        return ''
      }
    }

    function composeFilter(): string {
      const $filters = []

       if (keyword.value != '') {
        $filters.push(`&$filter=contains(company/companyName,'${keyword.value}')`)
      }
      
      if(advancedSearchOption.projectType) {
        $filters.push(`company/projectType eq '${advancedSearchOption.projectType}'`)
      }

      if(advancedSearchOption.communicationType) {
        $filters.push(`communicationType eq '${advancedSearchOption.communicationType}'`)
      }

      if(advancedSearchOption.communicateOn) {
        $filters.push(`communicateOn eq ${moment(advancedSearchOption.communicateOn).format('yyyy-MM-DD')}`)
      }
      
      if(advancedSearchOption.ownerFullName) {
        $filters.push(`communicationParticipants/any(c: contains(c/participant/fullName,'${advancedSearchOption.ownerFullName}'))`)
      }

      if(advancedSearchOption.headDept) {
        $filters.push(`contains(headDepartment,'${advancedSearchOption.headDept}')`)
      }

      if(advancedSearchOption.assitDept) {
        $filters.push(`contains(cooperationDepartment,'${advancedSearchOption.assitDept}')`)
      }
      return  $filters.length > 0 ? ('&$filter=' + $filters.join(' and ')) : ''
    }

    function searchRecords() {
      const $filter = composeFilter();
      const $expand = 'company($select=companyName,projectType),CommunicationParticipants($select=participantId,regardingParticipantType;$expand=Participant($select=id,fullName))'

      gridApi.showLoadingOverlay()
      axiosService.get(`odata/communications/$count?${$filter}`).then((res: AxiosResponse<number>) => {
        recordCount.value = res.data
      })
      axiosService.get(`odata/communications?$expand=${$expand}${$filter}&$orderby=communicateOn desc&$top=${pageSize.value}&$skip=${(currentPage.value - 1) * pageSize.value}`).then(res => {
        communications.splice(0, communications.length)
        Array.prototype.push.apply(
          communications,
          res.data.value.map((c: Communication, index: number) => {
            return {
              id: c.id,
              serialNo: ((currentPage.value - 1) * pageSize.value ) + (index + 1),
              company: c.company,
              companyId: c.companyId,
              communicateOn: c.communicateOn,
              communicationType: c.communicationType,
              ourParticipateMembers: c.communicationParticipants
                .filter(p => p.regardingParticipantType == 2)
                .map(p => p.participant.fullName)
                .join(', '),
              headDepartment: c.headDepartment,
              cooperationDepartment: c.cooperationDepartment,
              memo: c.memo,
              potentialQuestions: c.potentialQuestions,
              feedbackAfterContact: c.feedbackAfterContact,
              owners: c.communicationParticipants
                .filter((p: CommunicationParticipants) => p.regardingParticipantType == 1)
                .map((p: CommunicationParticipants) => p.participant.fullName)
                .join(', '),
              participants: '',
              createdBy: c.createdBy,
              createdOn: c.createdOn,
              modifiedOn: c.modifiedOn,
              modifedBy: c.modifedBy
            }
          })
        )
        gridApi.setRowData(communications)
        gridApi.hideOverlay()
      })
    }

   // share record
    const showShareModal = ref(false)
    const shareRecordModal = ref<any>(null)
    const shareTargetUsers = reactive([] as Array<SelectOption>)
    const selectedUserForShare = ref([] as Array<SelectOption>)
    const sharedRecords = ref([] as Array<RecordShare>)
    function searchUser(keyword: string) {
      if(!keyword){
        return
      }
      axiosService.get(`odata/users?$top=20&$skip=0&$select=fullName,id&$filter=userName ne 'admin' and contains(fullName,'${keyword}')`).then((res: AxiosResponse<{ value: Array<User>}>) => {
        shareTargetUsers.splice(0, shareTargetUsers.length)
        shareTargetUsers.push(...res.data.value.map(u => ({
            key: u.fullName,
            value: u.id
          } as SelectOption)))
      })
    }

    function openShareRecoreModal() {
      showShareModal.value = true
      sharedRecords.value = []
      selectedUserForShare.value = []
      axiosService.get(`odata/recordshares?$expand=AssignToUser($select=FullName,Id)&$filter=recordId eq ${selectedCommunications[0].id} and recordType eq 2`).then((res: AxiosResponse<{value: Array<RecordShare>}>) => {
        if(res.data.value.length > 0)
        {
          res.data.value.forEach(r => {
            sharedRecords.value.push({
              id: r.id,
              recordId: r.recordId,
              recordType: r.recordType,
              assignTo: r.assignTo,
              needToRemove: false,
              assignToUser: r.assignToUser
            } as RecordShare)
          })
        }
      })
    }

    function cancelRecordShare(record: RecordShare)
    {
      const index = sharedRecords.value.indexOf(record)
      if(index != -1) {
        sharedRecords.value.splice(index, 1)
      }

      axiosService.delete(`api/recordshares/${record.id}`).then(() => {
        initToast({
          message: '取消分享成功!',
          color: 'success',
          closeable: true,
          duration: 2000
        })
      })
    }

    function shareRecord()
    {
      axiosService.post(`api/recordshares`, {
        recordIds: selectedCommunications.map(r => r.id),
        recordType: 2, // community
        assignTos: selectedUserForShare.value.map(o => o.value)
      }).then(res => {
        initToast({
          message: '分享沟通信息成功!',
          color: 'success',
          closeable: true,
          duration: 2000
        })
        shareRecordModal.value.hide()
      })
    }

    function ExportXlsx() {
      const exportService = new ExportExcelService(selectedCommunications.map(c => ({
        serialNo: c.serialNo,
        companyName: c.company.companyName,
        projectType: selectOptionService.optionCollection.get('ProjectType')?.find(o => o.value == c.company.projectType)?.key,
        communicationType: selectOptionService.optionCollection.get('CommunicationType')?.find(o => o.value == c.company.projectType)?.key,
        communicateOn: moment(c.communicateOn).format('ll'),
        headDepartment: c.headDepartment,
        cooperationDepartment: c.cooperationDepartment,
        owners: c.owners,
        ourParticipateMembers: c.ourParticipateMembers,
        feedbackAfterContact: c.feedbackAfterContact,
        potentialQuestions: c.potentialQuestions,
      })))
      exportService.Export2Excel("项目跟进记录", [
        { header: '序号', key: 'serialNo', width: 10 },
        { header: '企业名称', key: 'companyName', width: 50 },
        { header: '项目类别', key: 'communicationType', width: 20 },
        { header: '沟通方式', key: 'communicateOn', width: 20 },
        { header: '沟通时间', key: 'headDepartment', width: 20 },
        { header: '投资/注册资金', key: 'cooperationDepartment', width: 20 },
        { header: '具体负责人', key: 'owners', width: 30 },
        { header: '配合人员', key: 'ourParticipateMembers', width: 30 },
        { header: '进展情况', key: 'feedbackAfterContact', width: 80 },
        { header: '存在问题', key: 'potentialQuestions', width: 50 },
      ])
    }

    return {
      name: 'communication',
      keyword,
      companyName: ref('company name testing'),
      gotoNewPage,
      gotoEdit,
      columnColumnDefs,
      ...useSelectOptions(),
      searchRecords,
      selectedCommunications,
      currentPage,
      onSelectionChanged,
      onGridReady,
      isMobile: CommonUtil.isMobile(),
      pageCount,
      pageSize,
      currengPageChanged,
      deleteRecords,
      isAdvancedSearch,
      advancedSearchOption,
      formatDate,
      // share records
      showShareModal,
      openShareRecoreModal,
      cancelRecordShare,
      shareRecordModal,
      shareTargetUsers,
      sharedRecords,
      searchUser,
      shareRecord,
      selectedUserForShare,
      hasPermissionToShare: store.state.userInfo.roles && store.state.userInfo.roles !== 'staff',
       // export
      ExportXlsx,
    }
  }
})
</script>

<style lang="scss" scoped>
.company {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #fff;
  .search {
  }
  .ribbon-btn::v-deep {
    .va-button--small .va-button__content {
      padding: 0 0.8rem;
    }
  }
  .table {
    flex: 1;
    position: relative;
    .container {
      position: absolute;
    }
  }
}
</style>
